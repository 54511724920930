import $ from 'jquery';

export default function accordion() {
  
  function init() {
    $('.box-accordion .box-accordion__title').on('click', function(e) {
      if(!$(this).parents('.box-accordion').hasClass('box-accordion--open')) {
        $(this).parents('.box-accordion').addClass('box-accordion--open');
      } else {
        $(this).parents('.box-accordion').removeClass('box-accordion--open');
      }
    });
  }
	
  $( document ).ready(function() {
    if($('.box-accordion .box-accordion__title').length > 0) {
      init();
    }
  });
}