import $ from 'jquery';

export default function sharing() {
  
  function init() {
    $('.sharing__text, .sharing__icon--share').on('click', function(){
      $(this).parent('.sharing').toggleClass('active');
    });
  }
  
  $( document ).ready(function() {
    if($('.sharing .sharing__text').length > 0) {
      init();
    }
  });
  
}