import $ from 'jquery';

export default function hamburger() {
  
  function init() {
    $('.hamburger').on('click', function() {
      if(!$(this).hasClass('open')) {
        $(this).addClass('open');
        $('.navigation').addClass('open');
      } else {
        $(this).removeClass('open');
        $('.navigation').removeClass('open');
      }
    });
  }
  
  $( document ).ready(function() {
    if($('.hamburger').length > 0) {
      init();
    }
  });
  
}