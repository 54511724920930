import $ from 'jquery';
import cookie from 'jquery.cookie';

export default function cookieBar() {
  
  function init() {
    
    if ($.cookie('cookiebar') == null) {
      $('#cookieBar').show();
    }
    
    //Click su "Chiudi" oppure su qualsiasi pagina del sito (esclusa la "Policy Cookie")
    if ($('#cookieBar').is(':visible')) {
			$('#cookieBar .cookie-message__close, a[href^="/site/"]:not([href*="privacy-e-cookie-policy"])').on('click', function() {
				$.cookie('cookiebar', 'hide', { path: '/', secure: false, domain: '', expires: 365 });
        
        $('#cookieBar').hide();
			});
		};
  }
  
  $( document ).ready(function() {
    if($('#cookieBar').length > 0) {
      init();
    }
  });
  
}