import $ from 'jquery';

import utils from './utils';
import sharing from './sharing';
import hamburger from './hamburger';
import cookieBar from './cookie-bar';
import accordion from './box-accordion';
import slider from './slider';

utils();
sharing();
hamburger();
cookieBar();
accordion();
slider();