import $ from 'jquery';
import 'svgxuse';

export default function utils() {
  
  //Javascript abilitati
  $('html').removeClass('no-js');
  
  //External link _blank
	$('a[href^=http], a.external').on('click', function() {
		window.open(this.href);
		return false;
  });
  
  //Header fixed
  if ($('.header').length > 0) {
    var $document = $(document),
      $element = $('.header'),
      className = 'fixed';
    
    $document.on('scroll', function() {
      if ($document.scrollTop() >= 10) {
        $element.addClass(className);
      } else {
        $element.removeClass(className);
      }
    });
  }

  //Bottone switch ricerca smartphone
  $('.btn-switch.active').on('click', function(){
    $(this).hide();
    $('.btn-switch.disable').show();
    $('.header__search').slideToggle();
  });
  $('.btn-switch.disable').on('click', function(){
    $(this).hide();
    $('.btn-switch.active').show();
    $('.header__search').slideToggle();
  });
  
}