import $ from 'jquery';
import 'slick-carousel';

export default function slider() {
  
  function sliderComuni() {
    $('.slider-comuni').slick({
      arrows: true,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 8,
      slidesToScroll: 8,
      prevArrow: '<button class="slick-arrow slick-prev"></button>',
      nextArrow: '<button class="slick-arrow slick-next"></button>',
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 519,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 373,
          settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    });
  }
  
  $( document ).ready(function() {
    
    if($('.slider-comuni').length > 0) {
      sliderComuni();
    }

  });
}